<template>
    <div class="p-2" v-if="user">

        <div class="d-flex justify-content-between">
            <router-link to="/bussiness" class="h4 mb-0 text-white d-flex align-items-center" style="">
                <i class="bx bx-chevron-left" style="font-size: 20px;color: #FFAD32;"></i>
                Back
            </router-link>
            <router-link to="/trainer-register" class="h4 mb-0 text-white d-flex align-items-center" style="gap:5px">
                Edit
                <i class="bx bxs-edit" style="font-size: 20px;color: #FFAD32;"></i>
            </router-link>
        </div>

        <div class="d-flex mt-1">
            <img :src="user.user_picture ? user.user_picture : default_img"
                class="rounded-circle m-auto" style="height:100px;width:100px" />
        </div>  

         
        <div class="text-center my-1">
            <h3 class="text-white h4 mb-0">{{user.user_name}}</h3> 
            <p class="mt-1 text-white">
                {{user.trainer.trainer_description}}
            </p>
        </div>  

        <div class="d-flex">
            <div class="badge bg-danger m-auto" v-if="user.trainer.is_approved == 'n'">
                Waiting for approval
            </div>
        </div>

        <!-- product list -->
        <div class="d-flex align-items-center justify-content-between my-3" style="gap:10px">
            <h4 class="mb-0 text-white font-weight-bolder">Training List</h4>
            <router-link to="/bussiness/training/form" class="mb-0 h5 text-white font-weight-bolder">Add Service</router-link>
        </div>

        <div v-if="training_list.length < 1">
            <h4 class="text-center text-white">No Data Available</h4>
        </div>

        <div class="d-flex flex-column" style="gap:10px">
            <router-link :to="`/bussiness/training/form/${item.tr_id}`" class="d-flex align-items-center" style="gap:10px" :key="item.tr_id+'TRAINING'" v-for="item in training_list">
                <img style="height: 80px;width: 80px;background-color: #D9D9D9;border-radius: 10px;object-fit: cover;"/>
                <div class="flex-fill">
                    <div class="d-flex justify-content-between w-100" style="gap:10px">
                        <h4 class="text-white font-weight-bolder mb-0">{{item.tr_name}}</h4>
                        <h4 class="text-white font-weight-bolder mb-0">${{ item.tr_price }}</h4>
                    </div>
                    <h6 class="text-white font-weight-normal" style="margin:.5em 0">{{ item.tr_details }}</h6>
                    <div class="d-flex w-100 justify-content-between" style="gap:10px">
                        <div class="d-flex align-items-center" style="gap:5px;width: 10% !important;">
                            <i class="bx bxs-star" style="color: #FFAD32;"></i>
                            <div class="text-white">{{ item.tr_rating }}</div>
                        </div>
                        <div class="text-white text-right" style="width: 40% !important;">Sold: {{ item.tr_sold }} Times</div>
                    </div>
                </div>
            </router-link>
        </div>
        <!-- end product list -->
 
        <div style="height: 1px;width: 100%;background-color: #FFAD32;" class="my-2"></div>
        
    </div>
</template>

<script>

import store from '@/store'
import Cookies from 'universal-cookie'

let cookies = new Cookies()

export default {
    computed: {
        user(){
            return store.state.auth.USER
        },
        training_list(){
            return store.state.training.TRAINING_LIST || []
        }
    },
    methods: {
        getData(){
            let payload = {
                user_id: cookies.get('token'),
                page: 1,
                limit: 999
            }
            store.dispatch('training/GetTrainingList', payload)
        }
    },
    mounted(){
        this.getData()
    },
    data(){
        return {
            default_img: 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png'
        }
    }
}

</script>